var url = window.location.href;

var fbShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
var twitterShareUrl = "https://twitter.com/intent/tweet?url=" + url;
var linkedinShareUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + url;

var mediaUrl = { Facebook: fbShareUrl, Twitter: twitterShareUrl, LinkedIn: linkedinShareUrl };

function onClickShare(media) {
  gtag("event", "share", { method : media, "event_label": "Shared url: " + url });
  window.open(mediaUrl[media], "ShareOn" + media);
}
