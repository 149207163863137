//
// Navbar scripts
//

window.onload = function () {
  let path = window.location.pathname;
  let page = path.split('/')[1];

  let link = $('a.nav-link[href="/' + page + '"]');
  $(link).parent().addClass('active').siblings().removeClass('active');
}
