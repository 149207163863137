let videoModals = document.getElementsByClassName("video-modal");

Array.prototype.forEach.call(videoModals, function(modal) {
  $(modal).on("hidden.bs.modal", function () {
    let video = modal.getElementsByTagName("video")[0];
    let watchedTime = Math.trunc(video.currentTime/video.duration*100) + "%";
    gtag("event", "video_stop", {
      "event_category" : "video",
      "event_label" : video.id + ", watched: " + watchedTime
    });
    video.pause();
  });
});

function playVideo(id) {
  gtag("event", "video_play", {
    "event_category" : "video",
    "event_label" : id
  });
  var video = document.getElementById(id);
  video.play();
}
